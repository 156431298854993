<template>
    <article class="skill-card" :class="{ inverse: card.key % 2 === 0 && screenWidth > 912 }">
        <img :src="require(`@/assets/images/${card.image}`)" :alt="card.imageAlt">
        <div class="text-wrapper">
            <h2>{{ card.title }}</h2>
            <p>{{ card.description }}</p>
        </div>
    </article>
</template>

<script>
export default {
    name: 'SkillCard',
    props: {
        card: Object
    },
    data() {
        return {
            screenWidth: window.innerWidth
        }
    },
    methods: {
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        }
    },
    created() {
        window.addEventListener('resize', this.updateScreenWidth);
    },
    unmounted() {
        window.removeEventListener('resize', this.updateScreenWidth);
    }
}
</script>

<style>
    .skill-card {
        align-items: center;
        background-color: var(--tertiary-color);
        border-radius: 0 3rem 0 3rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        height: 65vh;
        text-align: center;
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        transition: all .3s ease;
    }

    .skill-card > * {
        margin-top: 1rem;
    }

    .text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 70%;
        text-align: left;
    }

    .skill-card:hover {
        background-color: var(--hover-color);
        border-radius: 3rem 0 3rem 0;
        color: var(--text-color-light-background);
        transform: translate3D(0,-1px,0) scale(1.05);
        transition: all .3s ease;
    }

    .skill-card img {
        border-radius: 50%;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4), 0 12px 30px 0 rgba(0, 0, 0, 0.38);
        margin-top: 5vh;
        max-height: 10vh;
        max-width: 10vh;
    }

    @media screen and (max-width: 1280px) {
        .skill-card {
            height: 75vh;
        }

        .text-wrapper p {
            font-size: 1.7vh;
        }
    }

    @media screen and (max-width: 1024px) {
        .skill-card {
            height: 80vh;
        }

        .text-wrapper p {
            font-size: 2.2vh;
        }

        .text-wrapper h2 {
            font-size: 2.5vh;
        }

        .skill-card img {
            max-height: 15vh;
            max-width: 15vh;
        }

        .inverse {
            border-radius: 3rem 0 3rem 0;
        }

        .inverse:hover {
            border-radius: 0 3rem 0 3rem;
        }
    }

    @media screen and (max-width: 912px) {
        .skill-card {
            height: 85vh;
        }
    }
    
    @media screen and (max-width: 475px) {
        .skill-card {
            height: auto;
        }

        .text-wrapper {
            margin-bottom: 5vh;
        }
    }
</style>
<template>
    <div class="container-details">
        <section class="introduction-section-wrapper">
            <div class="introduction-text-wrapper">
                <h1 class="title">About me</h1>
                <img class="introduction-image" src="../../assets/images/intro-photo.jpg" alt="Mitchell van Rijswijk" v-if="screenWidth <= 912">
                <p class="introduction-text">My name is Mitchell van Rijswijk, a Dutch, 24-year-old student currently in the third year of pursuing a bachelor’s degree in software development at the HAN University of Applied Sciences. With a programming journey dating back to 2018, I have accumulated extensive knowledge from various angles, spanning many of the most common programming languages and concepts.</p>
                <p class="introduction-text">My experience encompasses active participation in educational endeavors with real-world implications. This includes crafting a decision tree for client assistance in a legal counseling insurance company, developing an internal chat plugin for the back-office application of a web development firm, and engineering a social media scraper to detect mentions of COVID-19-related disturbances for municipal police forces.</p>
                <p class="introduction-text">Adept at grasping the overarching scope of software projects, I specialize in resolving architectural challenges and teamwork. My interests lie in adhering to clean code principles, leveraging design patterns, and I get great satisfaction from code optimization. Driven by an insatiable thirst for knowledge since childhood, I have always remained enthusiastic about continuous learning. Over time, I have cultivated a renewed fascination with mathematics and its extensive application in deep learning.</p>
                <p class="introduction-text">I would like to invite you to explore the remainder of my profile, where you'll find details regarding my proficiency in various programming languages, an overview of completed projects, and avenues for contacting me. Please feel free to reach out at your convenience.</p>
            </div>
            <img class="introduction-image" src="../../assets/images/intro-photo.jpg" alt="Mitchell van Rijswijk" v-if="screenWidth > 1024">
        </section>
        <div class="about-me-row">
            <article class="about-me">
                <div class="about-me-wrapper">
                    <h1>Get to know my work!</h1>
                    <div class="navigation-section">
                        <button class="quick-navigation-button" @click="this.scrollToSection('my-skills')">Skills</button>
                        <button class="quick-navigation-button" @click="this.scrollToSection('my-projects')">Projects</button>
                        <button class="quick-navigation-button" @click="this.scrollToSection('contact-me')">Contact</button>
                    </div>
                </div>
            </article>
            <img class="introduction-image" src="../../assets/images/intro-photo.jpg" alt="Mitchell van Rijswijk" v-if="screenWidth <= 1024 && screenWidth > 912">
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutView',
    data() {
        return {
            screenWidth: window.innerWidth
        }
    },
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            this.$emit('redirect', sectionId)
            if (sectionId == 'about-me') {
                this.scrollToTop();
            } else if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        }
    },
    created() {
        window.addEventListener('resize', this.updateScreenWidth);
    },
    unmounted() {
        window.removeEventListener('resize', this.updateScreenWidth);
    }
}
</script>

<style scoped>
    .container-details {
        margin-top: 2.5vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 2.5vh;
        width: 60vw;
    }

    .introduction-section-wrapper {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 5vw;
        width: 60vw;
    }

    .introduction-image {
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        justify-self: flex-end;
        border-radius: 3rem 0 3rem 0;
        max-height: 60vh;
        width: 20vw;
    }

    .introduction-text {
        margin-top: 2vh;
        justify-self: flex-start;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 1.6;
        max-width: 35vw;
    }

    .introduction-text-wrapper {
        align-self: left;
    }

    .about-me {
        align-self: center;
        align-items: center;
        background-color: var(--tertiary-color);
        border-radius: 3rem 0 3rem 0;
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        display: flex;
        gap: 5vh;
        flex-direction: column;
        height: 35vh;
        transition: all .3s ease;
        width: 60vw;
    }

    .about-me:hover {
        border-radius: 0 3rem 0 3rem;
        background-color: var(--hover-color);
        color: var(--text-color-light-background);
        transform: translate3D(0,-1px,0) scale(1.03);
        transition: all .3s ease;
    }

    .about-me-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5vh;
        margin: auto;
    }

    .navigation-section {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 10vw;
        justify-content: center;
    }

    .quick-navigation-button {
        background-color: var(--secundary-color);
        color: var(--text-color);
        font-size: 1.6vh;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 15px 0 rgba(0, 0, 0, 0.38);
        border: none;
        height: 15vh;
        width: 15vh;
        border-radius: 50%;
        transition: all .15s ease-in-out;
    }

    .quick-navigation-button:hover {
        background-color: #fff;
        border-radius: 50% 0 50% 0;
        border: 3px solid var(--primary-color);
        color: var(--text-color-light-background);
        cursor: pointer;
        transition: all .15s ease-in-out;
    }

    @media screen and (max-width: 1280px) {
        .introduction-text {
            font-size: 1.7vh;
            max-width: 30vw;
        }

        .introduction-image {
            margin-top: 0;
            max-height: 70vh;
            width: 25vw;
        }
    }

    @media screen and (max-width: 1024px) {
        .introduction-section-wrapper {
            flex-direction: column;
        }

        .introduction-text-wrapper {
            width: 60vw;
        }

        .introduction-text {
            font-size: 2.2vh;
            max-width: 60vw;
        }

        .about-me {
            width: 28vw;
            height: 70vh;
        }

        .about-me-wrapper h1 {
            font-size: 2.8vh;
        }

        .navigation-section {
            flex-direction: column;
            gap: 5vh;
        }

        .quick-navigation-button {
            font-size: 2.2vh;
        }

        .about-me-row {
            display: flex;
            flex-direction: row;
            gap: 4vw;
        }

        .introduction-image {
            border-radius: 0 3rem 0 3rem;
            width: 28vw;
        }
    }

    @media screen and (max-width: 912px) {
        .introduction-image {
            border-radius: 3rem 0 3rem 0;
            width: 60vw;
        }

        .about-me {
            width: 60vw;
        }
    }

    @media screen and (max-width: 475px) {
        .container-details {
            align-items: center;
        }

        .introduction-image {
            width: 90vw;
        }

        .introduction-text-wrapper {
            width: 90vw;
        }

        .introduction-text {
            max-width: 90vw;
        }

        .about-me {
            width: 90vw;
        }
    }
</style>
<template>
    <article class="project-card">
        <div class="text-wrapper">
            <h1>{{ project.name }}</h1>
            <p>{{ project.description }}</p>
            <div class="image-box" v-if="screenWidth <= 1280">
                <img :src="require(`@/assets/images/${project.image}`)" :alt="project.image_alt">
            </div>
        </div>
    </article>
</template>
<script>
export default {
    name: 'ProjectCard',
    props: {
        project: Object
    },
    data() {
        return {
            screenWidth: window.innerWidth
        }
    },
    methods: {
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        }
    },
    created() {
        window.addEventListener('resize', this.updateScreenWidth);
    },
    unmounted() {
        window.removeEventListener('resize', this.updateScreenWidth);
    }
}
</script>
<style scoped>
    .project-card {
        align-items: left;
        background-color: var(--tertiary-color);
        border-radius: 0 3rem 0 3rem;
        grid-column: span 3;
        gap: 2rem;
        height: 30vh;
        text-align: left;
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
        transition: all .3s ease;
        width: 33vw;
    }

    .project-card:hover {
        background-color: var(--hover-color);
        border-radius: 3rem 0 3rem 0;
        color: var(--text-color-light-background);
        transform: translate3D(0,-1px,0) scale(1.05);
        transition: all .3s ease;
    }

    .text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1vh;
        margin: auto;
        margin-top: 5%;
        width: 80%;
    }

    @media screen and (max-width: 1280px) {
        .project-card {
            height: 58vh;
            width: 60vw;
        }

        .text-wrapper {
            gap: 2vh;
        }

        .image-box {
            width: 100%;
        }

        img {
            justify-self: center;
            border-radius: 0 3rem 0 3rem;
            height: 30vh;
            width: 100%;
        }
    }

    @media screen and (max-width: 1024px) {
        .project-card {
            height: 75vh;
        }

        .text-wrapper {
            font-size: 2.2vh;
        }

        .text-wrapper h1 {
            font-size: 2.5vh;
        }

        img {
            height: 37.5vh;
        }
    }

    @media screen and (max-width: 912px) {
        .project-card {
            height: 90vh;
        }

        img {
            height: 20vh;
        }
    }

    @media screen and (max-width: 475px) {
        .project-card {
            width: 90vw;
            height: auto;
        }

        img {
            margin-bottom: 5vh;
        }

        .text-wrapper {
            align-items: flex-start;
        }

        .text-wrapper h1 {
            text-align: left;
            font-size: 3vh;
        }
    }
</style>
<template>
  <div class="main-container">
        <SideBar :activeSection="activeSection" @redirect="handleRedirect" />
        <PageContainer @redirect="handleRedirect" />
  </div>
</template>

<script>
import SideBar from './components/profile/SideBar.vue'
import PageContainer from './components/views/PageContainer.vue'

export default {
  name: 'App',
  components: {
    SideBar,
    PageContainer
  }, 
  data() {
    return {
      activeSection: 'about-me'
    }
  },
  methods: {
    handleRedirect(sectionId) {
      this.activeSection = sectionId;
    }
  }
}
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }

  #app {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5vh;
    --primary-color: #182c25;
    --secundary-color: #205d51;
    --tertiary-color: #5d8076;
    --hover-color: #fff7e9;
    --text-color: #fff;
    --text-color-light-background: var(--primary-color);
    --nav-button-hover-color: var(--primary-color);
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
 
  ::-webkit-scrollbar-track {
    background-color: #182c25;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #fff7e9;
    border-radius: 20px;
    background-clip: content-box;
  }

  body {
    background-color: var(--primary-color);
  }

  .main-container {
    background-color: var(--primary-color);
    min-height: 100vh;
  }

  .title {
    font-size: 5rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 1280px) {
    .title {
      font-size: 6vh;
    }
  }

  @media screen and (max-width: 475px) {
    .main-container {
      display: flex;
      flex-direction: column;
    }

    .title {
      font-size: 5vh;
    }
  }
  </style>

<template>
    <div class="container-details">
        <h1 class="title">Contact</h1>
        <p>Please feel free to reach out to me through the following means:</p>
        <div class="links">
            <a href="mailto:mitchell.van.rijswijk@gmail.com" target="_blank"><img class="nav-image" src="../../assets/images/gmail.png" alt="Gmail logo"></a>
            <a href="https://www.linkedin.com/in/mitchell-van-rijswijk/" target="_blank"><img class="nav-image" src="../../assets/images/linkedin.png" alt="LinkedIn logo"></a>
            <a href="https://github.com/MitchellvanR" target="_blank"><img class="nav-image" src="../../assets/images/github.png" alt="GitHub logo"></a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactView'
}
</script>

<style scoped>
    .container-details {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 50vh;
    }

    .links {
        display: flex;
        flex-direction: row;
        gap: 5vw;
        margin-top: 7.5vh;
    }

    .nav-image {
        border-radius: 50%;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4), 0 12px 30px 0 rgba(0, 0, 0, 0.38);
        height: 10vh;
        width: 10vh;
        transition: all .3s ease;
    }

    .nav-image:hover {
        transform: translate3D(0,-1px,0) scale(1.08);
        transition: all .15s ease;
    }

    @media screen and (max-width: 1280px) {
        .container-details p {
            font-size: 1.8vh;
        }
    }

    @media screen and (max-width: 1024px) {
        .container-details p {
            font-size: 2.2vh;
        }
    }

    @media screen and (max-width: 912px) {
        .container-details p {
            text-align: center;
            max-width: 55vw;
        }
    }
</style>
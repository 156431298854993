<template>
    <div class="container-details">
        <h1 class="title">My Skillset</h1>
        <ul class="card-list">
            <li v-for="card in skillCards" :key="card.key" class="card-container">
                <SkillCard :card="card" />
            </li>
        </ul>
    </div>
</template>

<script>
import SkillCard from '../entities/SkillCard.vue'

export default {
    name: 'SkillsView',
    data() {
        return {
            skillCards: [
                {key: 1, imageAlt: 'Java', title: 'Java', description: 'Throughout my time studying Software Engineering, Java has served as my primary programming language. As this is the language I learned first, I am familiar with its primary principles and nuances. Noteworthy projects I developed using Java encompass insurance decision tree software, a task management application, a chat platform, a CSS dialect interpreter, and a multiplayer text-based adventure game.', image: 'java-logo.png'},
                {key: 2, imageAlt: 'C#', title: 'C#', description: 'Expanding my repertoire, C# has made a recent inclusion in my toolkit. Leveraging on my grasp of the Object-Oriented Programming paradigm, I have adeptly acquainted myself with this widely-used language. Notable projects undertaken in C# encompass responsive chat applications, an IMDB scraper, and a machine learning image recognition model.', image: 'CS-logo.jpg'},
                {key: 3, imageAlt: '.NET', title: '.NET', description: 'Parallel to my exploration of C#, I have cultivated similar expertise within the .NET environment. Over time, I have honed my skills through a series of projects, including chat applications, an IMDB scraper, and an ML.NET image recognition model.', image: 'DOTNET-logo.png'},
                {key: 4, imageAlt: 'Python', title: 'Python', description: 'Python serves as one of my first programming languages. Recently, I\'ve used it extensively within the realm of machine learning. Python serves as my primary language for delving into the intricacies of machine learning concepts, including convolution and convolutional neural networks. Through Python, I\'ve familiarized myself with frameworks like TensorFlow, alongside other essential libraries such as NumPy and Matplotlib.', image: 'python-logo.png'},
                {key: 5, imageAlt: 'SQL', title: 'SQL', description: 'SQL has been an integral part of my software engineering journey since its start. Through extensive use of this language, I\'ve built a foundational comprehension of relational databases. Notable projects in which SQL played a pivotal role entail a Spotify clone and an information system for a gym, both simulated environments. As a plus, I possess proficiency in transactional SQL.', image: 'sql-logo.png'},
                {key: 6, imageAlt: 'Node.js', title: 'Node.js', description: 'Since 2020, Node.js has served as my primary tool for back-end development for personal projects. My focus with this framework has mostly centered around crafting MVC RESTful APIs. Noteworthy endeavors involving Node.js include the development of a multiplayer Mastermind clone and a queueing system for teachers tailored to students seeking assistance.', image: 'node-logo.png'},
                {key: 7, imageAlt: 'Vue.js', title: 'Vue.js', description: 'Vue.js has become my preferred frontend framework for personal projects, serving as my go-to solution for front-end development needs. While formal education in this framework is lacking, my prior experience with single-page application frameworks has made the transition to Vue seamless. Vue.js has been instrumental in a variety of projects, including chat applications, todo lists, a website for a study association, and even the construction of this very portfolio.', image: 'vue-logo.png'},
                {key: 8, imageAlt: 'Angular', title: 'Angular', description: 'Angular served as my first frontend framework, laying the groundwork for my understanding of similar single-page application frameworks. While my use of Angular has faded over time, the experience gleaned from it has fostered familiarity with analogous technologies. Notable projects developed using Angular include insurance decision tree software, a queueing system for teachers tailored to students seeking assistance, and a social media scraper.', image: 'angular-logo.png'}
            ]
        }
    },
    components: {
        SkillCard
    }
}
</script>

<style scoped>
    .title {
        align-self: center;
    }

    .card-list {
        display: grid;
        grid: auto / repeat(6, 1fr);
        gap: 5vh 2vw;
        list-style-type: none;
    }

    .card-container {
        grid-column: span 2;
    }

    .card-list .card-container:nth-child(5n), 
    .card-list .card-container:nth-child(5n-1) {
        grid-column: span 3;
    }

    .container-details {
        align-items: left;
        display: flex;
        flex-direction: column;
        color: var(--text-color);
        padding: 5vh 5vw;
        overflow: auto;
    }

    @media screen and (max-width: 1024px) {
        .card-list {
            grid: auto / repeat(2, 1fr);
        }

        .card-list .card-container {
            grid-column: auto !important; 
        }
    }

    @media screen and (max-width: 912px) {
        .card-list {
            grid: auto / repeat(1, 1fr);
        }

        .title {
            align-self: flex-start;
        }
    }

    @media screen and (max-width: 475px) {
        .title {
            align-self: center;
        }
    }
</style>
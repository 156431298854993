<template>
    <div class="content-container">
        <AboutView id="about-me" @redirect="sectionId => $emit('redirect', sectionId)" />
        <SkillsView id="my-skills" />
        <ProjectsView id="my-projects" />
        <ContactView id="contact-me" />
    </div>
</template>
<script>
import AboutView from './AboutView.vue';
import SkillsView from './SkillsView.vue';
import ProjectsView from './ProjectsView.vue';
import ContactView from './ContactView.vue';

export default {
    name: 'PageContainer',
    components: {
        AboutView,
        SkillsView,
        ProjectsView,
        ContactView
    }
}
</script>
<style>
    .content-container {
        align-items: center;
        justify-content: center;
        color: var(--text-color);
        display: flex;
        flex-direction: column;
        gap: 5vh;
        left: 25vw;
        position: relative;
        margin: 0;
        width: 74vw;
    }

    @media screen and (max-width: 475px) {
        .content-container {
            left: 0;
            margin-top: 12vh;
            width: 100vw;
        }

        .content-container .container-details {
            width: 90vw;
        }
    }
</style>
<template>
    <div class="container-sidebar">
        <ProfileSection/>
        <ProfileNavigation :activeSection="activeSection" @redirect="handleRedirect"/>
    </div>
</template>

<script>
import ProfileSection from './ProfileSection.vue'
import ProfileNavigation from './ProfileNavigation.vue'

export default {
    name: 'SideBar',
    components: {
        ProfileSection,
        ProfileNavigation
    },
    props: {
        activeSection: String
    },
    methods: {
        handleRedirect(sectionId) {
            this.$emit('redirect', sectionId)
        }
    }
}
</script>

<style>
    .container-sidebar {
        background-color: var(--secundary-color);
        border-radius: 0 6rem 0rem 0;
        color: var(--text-color);
        display: flex;
        flex-direction: column;
        position: fixed;
        height: 100vh;
        text-align: center;
        width: 25vw;
    }

    .container-sidebar img {
        border: 3px solid var(--primary-color);
        border-radius: 50%;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4), 0 12px 30px 0 rgba(0, 0, 0, 0.38);
        margin: 0 auto;
        max-width: 10vw;
        min-height: 10vw;
    }

    @media screen and (max-width: 912px) {
        .container-sidebar {
            border-radius: 0 3rem 0 0;
        }

        .container-sidebar img {
            max-width: 15vw;
            min-height: 15vw;
        }
    }

    @media screen and (max-width: 475px) {
        .container-sidebar {
            border-radius: 0;
            width: 100vw;
            height: 12vh;
            overflow: hidden;
            z-index: 1;
        }
    }
</style>
<template>
    <nav class="navigation-sidebar">
        <ul>
            <li v-for="link in links" @click="scrollToSection(link.destination)" :class="{ active: activeSection === link.destination }" :key="link.id">
                {{ link.text }}
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'ProfileNavigation',
    data() {
        return {
            links: [
                {id: 1, text: 'About Me', destination: 'about-me'},
                {id: 2, text: 'My Skillset', destination: 'my-skills'},
                {id: 3, text: 'My Projects', destination: 'my-projects'},
                {id: 4, text: 'Contact', destination: 'contact-me'},
            ]
        }
    },
    props: {
        activeSection: String
    },
    mounted() {
        this.scrollToTop();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        scrollToSection(sectionId) {
            const section = document.getElementById(sectionId);
            this.$emit('redirect', sectionId)
            if (sectionId == 'about-me') {
                this.scrollToTop();
            } else if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        handleScroll() {
            const sections = document.querySelectorAll('.container-details');
            let sectionId = null;
            sections.forEach(section => {
                const rect = section.getBoundingClientRect();
                if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
                    sectionId = section.id;
                }
            });
                this.$emit('redirect', sectionId)
        }
    }
}
</script>

<style>
    .navigation-sidebar {
        max-height: 60%;
        position: relative;
    }

    .navigation-sidebar li {
        list-style-type: none;
        cursor: pointer;
    }

    .navigation-sidebar li {
        color: var(--text-color);
        display: block;
        padding: 1rem 3vw;
        text-decoration: none;
        transition: 0.25s;
    }

    .active,
    .navigation-sidebar li:hover {
        background-color: var(--nav-button-hover-color);
    }

    @media screen and (max-width: 1536px) {
        .navigation-sidebar li {
            font-size: 1.8vh;
        }
    }

    @media screen and (max-width: 912px) {
        .navigation-sidebar li {
            text-align: left;
        }
    }
</style>
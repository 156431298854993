import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('../components/views/AboutView')
        },
        {
            path: '/skills',
            name: 'skills',
            component: () => import('../components/views/SkillsView')
        },
        {
            path: '/projects',
            name: 'projects',
            component: () => import('../components/views/ProjectsView')
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import('../components/views/ContactView')
        }
    ]
})

export default router;
<template>
    <div class="container-details">
        <h1 class="title">Influential Projects</h1>
        <div class="project-card-container">
            <ProjectRow v-for="project in projects" :key="project.id" :project="project" />
        </div>
        <h2 class="see-more">If these projects have piqued your interest in my work, feel free to check out my GitHub below for more!</h2>
    </div>
</template>

<script>
import ProjectRow from '../entities/ProjectRow.vue'

export default {
    name: 'ProjectsView',
    components: {
        ProjectRow
    },
    data() {
        return {
            projects: [
                {id: 1, name: '2020 - Decision tree builder', description: 'I spearheaded the development of a decision tree builder tailored for the legal counseling insurance company ARAG. These decision trees served as efficient tools for clients seeking answers to their COVID-19 travel related questions during the ever changing times of the pandemic, thereby facilitating quick advice from the company. Throughout the project, my primary responsibilities centered on orchestrating the flow of the decision tree\'s construction. Additionally, I played a pivotal role in designing the various questionnaires, notifications, and prompts for advice, ensuring a seamless and user-friendly experience for both clients and company personnel.', image: 'arag.jpg', image_alt: 'Test'},
                {id: 2, name: '2021 - Social media scraper', description: 'One of my proudest achievements is co-creating a social media scraper for municipal police forces during the tumultuous times of the COVID-19 pandemic. Our goal was to monitor Telegram, Facebook, Instagram, Twitter, and WhatsApp for threatening speech about COVID-19 regulations. This enabled the police force to prevent riots and better keep peace. I primarily worked on the Telegram scraper, which was a significant learning experience in ethics in software development and project infrastructure.', image: 'police.png', image_alt: 'Test'},
                {id: 3, name: '2023 - DungeonCrawler', description: 'DungeonCrawler is a multiplayer, text-based adventure game reminiscent of classic dungeon crawlers such as Pedit5 and Adventure. Developed collaboratively with a class of 25 students, my role in the project was pivotal. I, along with two other students, assumed responsibility for project management, overseeing meetings and steering architectural decisions and concerns. In terms of development, I played a significant role in ensuring the robustness of the peer-to-peer network between clients. My efforts focused on maintaining network integrity, even in the face of crashes or manual disconnects, thereby ensuring a seamless gaming experience.', image: 'dungeoncrawler-logo.jpeg', image_alt: 'Test'},
                {id: 4, name: '2024 - Computer vision in low voltage cabinets', description: 'Adopting a more scientific approach, this project is dedicated to the exploration of the computer vision domain. The objective entailed the design and implementation of a computer vision model for energy distributor Alliander, focusing on the analysis of low voltage cabinets. The outcome was to be translated into a machine-readable format like JSON. Through this endeavor, I acquired extensive insights into the intricate mathematical underpinnings of convolutional neural networks. This project has been instrumental in honing my research capabilities.', image: 'cnn.png', image_alt: 'Test'}
            ]
        }
    }
}
</script>

<style scoped>
    .container-details {
        min-height: 50vh;
        max-width: 60vw;
        display: flex;
        flex-direction: column;
        gap: 5vh;
    }

    .project-card-container {
        display: flex;
        flex-direction: column;
        gap: 10vh;
    }

    .see-more {
        align-self: center;
        text-align: center;
        max-width: 40vw;
    }

    @media screen and (max-width: 1280px) {
        .project-card-container {
            gap: 2vw;
        }
    }

    @media screen and (max-width: 912px) {
        .project-card-container {
            gap: 5vh;
        }

        .see-more {
            max-width: 60vw;
        }
    }

    @media screen and (max-width: 475px) {
        .container-details {
            max-width: 90vw;
        }
    }
</style>
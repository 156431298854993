<template>
    <article class="project-row">
        <div class="image-box" v-if="isOdd && screenWidth > 1280">
            <img :src="require(`@/assets/images/${project.image}`)" :alt="project.image_alt">
        </div>
        <ProjectCard :project="project" />
        <div class="image-box" v-if="isEven && screenWidth > 1280">
            <img :src="require(`@/assets/images/${project.image}`)" :alt="project.image_alt">
        </div>
    </article>
</template>
<script>
import ProjectCard from '../entities/ProjectCard.vue'

export default {
    name: 'ProjectRow',
    components: {
        ProjectCard
    },
    props: {
        project: Object
    },
    data() {
        return {
            screenWidth: window.innerWidth
        }
    },
    computed: {
        isEven() {
            return this.project.id % 2 === 0
        },
        isOdd() {
            return this.project.id % 2 !== 0
        }
    },
    methods: {
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        }
    },
    created() {
        window.addEventListener('resize', this.updateScreenWidth);
    },
    unmounted() {
        window.removeEventListener('resize', this.updateScreenWidth);
    }
}
</script>
<style scoped>
    .project-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2vw;
        justify-content: left;
        width: 60vw;
    }

    .image-box {
        display: flex;
        justify-content: center;
        width: 25vw;
    }

    img {
        justify-self: center;
        height: 30vh;
        width: 100%;
    }

    @media screen and (max-width: 1280px) {
        .project-row {
            flex-direction: column;
        }

        .image-box {
            width: 60vw;
        }

        img {
            border-radius: 0 3rem 0 3rem;
            height: 35vh;
        }

        .project-row .image-box {
            display: none;
        }
    }

    @media screen and (max-width: 475px) {
        .project-row {
            width: 90vw;
        }
    }
</style>
<template>
    <div class="profile">
        <img src="../../assets/images/profile.jpg" alt="">
        <h1>Mitchell van Rijswijk</h1>
        <p>Third year Software Engineering student</p>
    </div>
</template>

<script>
export default {
    name: 'ProfileSection'
}
</script>

<style>
    .profile {
        max-height: 40%;
        padding: 3vw;
        position: relative;
    }

    .profile > * {
        margin-top: 1rem;
    }

    @media screen and (max-width: 1280px) {
        .profile p {
            font-size: 1.7vh;
        }
    }

    @media screen and (max-width: 912px) {
        .profile p {
            font-size: 1.8vh;
            text-align: left;
        }

        .profile h1 {
            font-size: 2.3vh;
            text-align: left;
            width: 22vw;
        }

        .profile {
            margin-bottom: 2.5vh;
        }
    }

    @media screen and (max-width: 475px) {
        .profile p, h1 {
            text-align: center;
        }

        .profile h1 {
            width: 97vw;
        }
    }
</style>